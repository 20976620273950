/* Google Font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
/* Google Font - Roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');
 

/* Body */
/* ---------------------------------------------------------------------- */
.Spiceway .body {
  font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #303030;
  background-color: #ffffff;
  font-size: 16px;
}
/* Header */
/* ---------------------------------------------------------------------- */
.Spiceway .header-top {
  display: flex; 
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background-color: #1B2761;
}
.Spiceway .header-top a.login-wrapper {
  display: flex;
  color: #FBB005;
  font-size: 14px;
}
.Spiceway .header-top a.login-wrapper:hover {
  text-decoration: none;
  color: rgba(251, 176, 5, 0.80);
}
.Spiceway .mlr5 {margin-left: 5px; margin-right: 5px;}
.Spiceway .header-top a.shopping-cart {
  font-size: 16px;
  width: 30px;
  height: 30px;
  color: #FBB005;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Spiceway .header-top a.shopping-cart:hover {
  text-decoration: none;
  color: rgba(251, 176, 5, 0.80);
}
.Spiceway .header-top a.shopping-cart .cart-count {
  position: absolute;
  right: -10px;
  bottom: -4px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-color: #f60303;
  border: 2px solid #1B2761;
}
.Spiceway .navbar-nav.m-app-nav {margin-top: 20px;}
.Spiceway .navbar-brand .brand-logo {height: 30px;}
.Spiceway .navbar-toggler {
  border: 0;
  font-size: 24px;
}
.Spiceway .navbar-light .navbar-toggler i.fa-bars {
  color: #0E98E1;
}
.Spiceway .navbar-light .navbar-toggler i.fa-times {
  color: #FBB005;
}
.Spiceway .navbar-brand {margin-right: 40px;}
.Spiceway .navbar-light .navbar-nav .nav-link {
  color:#0E98E1;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 16px 16px 0;
}
.Spiceway .navbar-light .navbar-nav .nav-link:hover {text-decoration: none;color: #FBB005;}
.Spiceway .navbar-light .navbar-nav .nav-link.active {color: #FBB005;}
.Spiceway .navbar-light .navbar-nav .nav-link:active, .Spiceway .navbar-light .navbar-nav .nav-link:focus {color: #FBB005;}
/* Hero Banner */
/* ---------------------------------------------------------------------- */
.Spiceway .hero-banner  {
  background-image: linear-gradient(
  rgba(0,0,0,0.0), 
  rgba(0,0,0, 0.35)), 
  url("../images/seafood-hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.Spiceway .hero-banner h1, h3 {
  color: #ffffff;
  text-align: center;
}
.Spiceway .hero-banner h1 {
  font-weight: 700; 
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 3px;
  word-spacing: 10px;
  text-shadow: -1px -1px 6px rgb(239 151 10 / 84%);
}
.Spiceway .hero-banner h3 {
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  text-transform: none;
}
.Spiceway .hero-text-wrapper {
  background-color: rgba(0,0,0, 0.35);
  padding: 2rem;
  margin: 2rem;
  border-radius: 8px;
}
.Spiceway .hero-banner.inner {
  height: 40vh;
}
.Spiceway .hero-banner.inner .hero-text-wrapper {
  padding: 0;
  margin: 1rem;
  background-color: transparent;
}
.Spiceway .hero-banner.inner h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 15px;
}
.Spiceway .hero-banner.inner h6 {
  color: #ffffff;
  text-align: justify;
}
/* Product Categories */
/* ---------------------------------------------------------------------- */ 
.Spiceway .section {
  padding-top: 30px;
  background-color: #ffffff;
}
.Spiceway .header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.Spiceway h3 {
  color: #1B2761;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  word-spacing: 3px;
}
.Spiceway .bottom-hr {
  display: inline-block;
  width: 80px;
  height: 4px;
  border-radius: 4px;
  background-color: #FBB005;
}
.Spiceway .item {
  padding: 0;
  display: inline-block;
  position: relative;
}
.Spiceway .item img {
  max-height: 400px;
  width: auto;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 5px;
}
.Spiceway .item .item-text {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.50);
  width: 100%;
}
.Spiceway .item .item-text h5 {
  padding: 16px 8px;
  color: #ffffff;
  margin-bottom: 0;
}
/* Product Carousel Styles */
.Spiceway .section .owl-theme .owl-dots .owl-dot.active span, .Spiceway .section .owl-theme .owl-dots .owl-dot:hover span {
  background: #FBB005;
}
.Spiceway .owl-theme .owl-nav [class*='owl-']:hover {
  background: #FBB005 !important;
}
.Spiceway .owl-nav {display: none;}
.Spiceway .owl-dots {margin-top: 10px;}
/* Brands */
/* ---------------------------------------------------------------------- */ 
.Spiceway .brands .item {
  padding: 20px; 
  margin: 0 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  border: 1px solid rgba(234, 237, 237, 0.70);
}
.Spiceway .brands .item img {box-shadow: none;}
/* Featured Products */
/* ---------------------------------------------------------------------- */
.Spiceway .featured-prd .item {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  margin: 10px 20px;
  padding: 20px;
  min-width: 300px;
  min-height: 300px;
}
.Spiceway .featured-prd .item .item-text {
  position: unset;
  background-color: #ffffff;
}
.Spiceway .featured-prd .item .item-text h5 {
  font-size: 16px; 
  color: #303030;
  font-weight: 500;
}
.Spiceway .featured-prd .item img {
  height: 270px; 
  width: auto;
  box-shadow: none;
}
.Spiceway .featured-prd a.item:hover {
  text-decoration: none;
}
/* Deals */
/* ---------------------------------------------------------------------- */
.Spiceway .section.deals {
  background-color: #F3F3F4;
  padding-bottom: 60px;
}
.Spiceway .section.deals .item img {
  box-shadow: none;
  border-radius: 12px;
  border: 3px solid #0E98E1;
}
.Spiceway .section.deals .item img:hover {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border-color: #FBB005;
}
/* Intro */
/* ---------------------------------------------------------------------- */
.Spiceway .intro  {
  background-image: linear-gradient(
  rgba(0,0,0,0.4), 
  rgba(0,0,0, 0.7)), 
  url("../images/hp-intro-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 33rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.Spiceway .section.intro .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Spiceway .intro h3 {
  color: #ffffff;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700; 
  font-size: 2rem;
  line-height: 2.5rem;;
  text-transform: none;
  margin-bottom: 30px;
}
.Spiceway .learn-more {
  background-color: #0E98E1;
  color: #ffffff;
  min-width: 200px;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Spiceway .learn-more h5 {
  margin-bottom: 0;
  text-transform: uppercase;
  margin-right: 15px;
}
.Spiceway .learn-more  i {
  font-size: 20px;
  color: #1B2761;
}
.Spiceway .learn-more:hover, .Spiceway .learn-more:focus, .Spiceway .learn-more:active {
  text-decoration: none;
  color: #ffffff;
  box-shadow: 0 3px 25px rgb(198 226 240 / 30%);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}
/* Footer */
/* ---------------------------------------------------------------------- */
.Spiceway .section.footer {
  margin-top: 60px;
  padding: 60px 0 60px 0;
  background-color: #1B2761;
}
.Spiceway .section.footer .container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
}
.Spiceway .section.footer .footer-address {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.Spiceway .section.footer .brand-logo {margin-bottom: 10px;}
.Spiceway .section.footer .brand-logo img {
  height: 60px;
  width: auto;
}
.Spiceway .section.footer .biz-name {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.Spiceway .biz-add-line {
  list-style: none;
  padding-left: 0;
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}
.Spiceway .section.footer .biz-phone {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}
.Spiceway .section.footer .footer-social {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.Spiceway .section.footer .social-link {margin-bottom: 30px;}
.Spiceway .section.footer .social-link a {
  font-size: 24px;
  color: #ffffff;
  margin-left: 15px;
}
.Spiceway .section.footer .social-link a:hover {
  color: rgba(255, 255, 255, 0.75);
}

.Spiceway .section.footer .policy ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.Spiceway .section.footer .policy ul li {
  display: inline-block;
  padding-left: 10px;
}
.Spiceway .section.footer .policy ul li a {
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
}
.Spiceway .section.footer .policy ul li a:hover {
  color: #FBB005;
}
.Spiceway .section.footer .policy ul li:first-child {
  padding-left: 0;
  padding-right: 10px;
  border-right: 1px solid #ffffff;
}
/* Product Page */
/* ---------------------------------------------------------------------- */
.Spiceway .hero-banner.inner.products {
  background-image: linear-gradient(
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.45)), 
    url("../images/products-bg.png");
}
.Spiceway .products .search button {
  font-size: 14px;
  padding: 4px 8px;
  border: 0;
  border-radius: 0;
  color: #ffffff;
  background-color: #1B2761;
}
.Spiceway .products .search button:active, 
.Spiceway .products .search button:focus {
  color: #ffffff !important;
  background-color: #1B2761 !important;
  box-shadow: none !important;
}
.Spiceway .products .search  input {
  font-size: 14px;
  padding: 4px 8px;
  height: 38px;
  border: 0;
  background-color: rgba(243, 243, 243, 0.75)
}
.Spiceway .products .menu-prd-cat button, .Spiceway .products .menu-prd-cat .dropdown-menu {width: 100%;}
.Spiceway .products .input-group-append button {
  background-color: #FBB005 !important;
}
.Spiceway .products .input-group-append button:active, 
.Spiceway .products .input-group-append button:focus {
  color: #ffffff !important;
  background-color: #FBB005 !important;
  box-shadow: none !important;
}
.Spiceway .products .list-group-item.active {
  background-color: #1B2761;
  color: #FBB005;
}
.Spiceway .prd-cat-title {margin-top:20px; margin-bottom: 0;}
.Spiceway .mt20 {margin-top: 20px;}
.Spiceway .mb0 {margin-bottom: 0;}
.Spiceway .prd-item {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 10px;
  margin-top: 30px;
  text-align: center;
}
.Spiceway .prd-item .prd-item-link {
  text-decoration: none;
  display: inline-block;
}
.Spiceway .prd-item .prd-item-text h5 {
  font-size: 16px;
  color: #303030;
  margin-bottom: 20px;
}
.Spiceway .prd-item .prd-item-text h6 {
  padding: 8px 16px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #FBB005;
  background-color: #ffffff;
  border: 3px solid #FBB005;
  border-radius: 50px;
  display: inline-block;
  min-width: 100px;  
}
.Spiceway .prd-count-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.Spiceway .prd-count-inner button {
  font-size: 12px;
  color: #212529;
  padding: 5px 10px;
  background-color: #F3F3F4;
  border: 0;
  width: 40px;
  height: 30px;
}
.Spiceway .prd-count-inner .qty-no input {
  width: 50px;
  height: 30px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.Spiceway .prd-count-inner button.add-btn {
  font-size: 14px;
  font-weight: 600;
  color: #FBB005;
  padding: 5px 8px;
  background-color: #1B2761;
  border: 0;
  width: auto;
  height: 30px;
  text-transform: uppercase;
}
.Spiceway .prd-count-inner .out-of-stock {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #c4c1c1;
  margin-right: 15px;
}
/* pagination */
.Spiceway .pagination {margin-top: 50px;float: right;}
.Spiceway .page-link {
  color: #0E98E1;
}
.Spiceway .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0E98E1;
  border-color: #0E98E1;
}
/* Contact Us */
/* ---------------------------------------------------------------------- */
.Spiceway .hero-banner.inner.contactus {
  background-image: linear-gradient(
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.65)), 
    url("../images/contactus-img.jpg");
}
.Spiceway .section.contactus .biz-address {
  padding: 20px;
  background-color: #F3F3F4;
  margin-bottom: 30px;
}
.Spiceway .section.contactus .biz-name {
  text-transform: uppercase;
  font-weight: 600; 
  margin-bottom: 15px;
}
.Spiceway .hero-banner.inner.contactus h3 {margin-bottom: 0;}
.Spiceway .section.contactus .biz-add-line {
  color: #303030;
  text-align: left;
  font-size: 16px;
}
.Spiceway .mob-order {order: -1;}
.Spiceway .section.contactus .contact-top .notes {
  font-size: 14px;
  color: #c4c1c1;
}
.Spiceway .section.contactus .contact-top .notes span {
  font-weight: 700;
  margin-right: 5px;
  color: #ff1200;
}
.Spiceway .required::after {
  content: " *";
  color: #ff1200;
}
.Spiceway .section.contactus .form-group {margin-top: 16px;}
.Spiceway .section.contactus label {font-weight: 500;}
.Spiceway .section.contactus input {
  background-color: #F3F3F4;
  border: 0;
  border-radius: 0;
  padding: 24px;
}
.Spiceway .section.contactus textarea {
  background-color: #F3F3F4;
  border: 0;
  border-radius: 0;
  padding: 24px;
  display: block;
  width: 100%;
}
.Spiceway .form-btn-wrapper {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
.Spiceway .form-btn-wrapper button {
  text-transform: uppercase;
  padding: 10px 20px;
  border: 0;
  box-shadow: none;
  background-color: #0E98E1;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  min-width: 200px;
  border-radius: 4px;
}
.Spiceway .form-btn-wrapper button:hover, 
.Spiceway .form-btn-wrapper button:focus, 
.Spiceway .form-btn-wrapper button:active {
  box-shadow: 0 3px 25px rgb(92 92 92 / 35%);
  transform: translateY(-1px);
}
.Spiceway .section.aboutus .form-btn-wrapper button:hover, 
.Spiceway .section.aboutus .form-btn-wrapper button:focus, 
.Spiceway .section.aboutus .form-btn-wrapper button:active {
  box-shadow: 0 3px 25px rgb(198 226 240 / 30%);
}
.Spiceway .container.map {
  margin-top: 40px;
}
.Spiceway .container.map .map-text {
  text-transform: uppercase;
  margin-bottom: 20px;
}
/* Map */
.Spiceway .map-responsive {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.Spiceway .map-responsive iframe {
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
/* About Us */
/* ---------------------------------------------------------------------- */
.Spiceway .hero-banner.inner.aboutus {
  background-image: linear-gradient(
    rgba(0,0,0,0.03), 
    rgba(0,0,0, 0.55)), 
    url("../images/about-us-top.jpg");
}
.Spiceway .hero-banner.inner.aboutus h3 {margin-bottom: 0;}
.Spiceway .section.aboutus .content-wrapper {
  margin-bottom: 30px;
}
.Spiceway .section.aboutus .content-wrapper .cont-cover {
  background-color: #1B2761;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
}
.Spiceway .section.aboutus .content-wrapper .cont-cover h4 {
  text-transform: uppercase;
  color: #FBB005;
  font-weight: 500;
  margin-bottom: 20px;
}
.Spiceway .section.aboutus .content-wrapper .cont-cover p { margin-bottom: 0; }
.Spiceway .section.aboutus .form-btn-wrapper {margin-bottom: 0;}
/* Responsive Media Query */
/* ====================================================================== */

/* @media (min-width: 768px) */
@media (min-width: 768px) {
  /* Header */
  /* ---------------------------------------------------------------------- */
  .Spiceway .navbar-nav.m-app-nav {margin-top: 0;}
  .Spiceway .header-top a.shopping-cart {
    font-size: 14px;
    width: 40px;
    height: 40px;
    border: 2px solid #FBB005;
    border-radius: 50%;
  }
  /* Hero */
  .Spiceway .hero-banner  {height: 85vh;}
  .Spiceway .hero-banner h1 {font-size: 3.5rem;}
  /* Inner Hero Product*/
  .Spiceway .hero-banner.inner  {
    min-height: 400px;
    padding: 20px;
    /* justify-content: flex-start; */
  }
  .Spiceway .hero-banner.inner h3 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }
  .Spiceway .hero-banner.inner .hero-text-wrapper {
    padding: 4rem;
  }
  .Spiceway .hero-banner.inner h6 {
    color: #ffffff;
    text-align: justify;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  /* Product Carousel Styles */
  .Spiceway .section {padding-top: 60px;}
  .Spiceway .owl-nav {display: block;}
  /* Intro */
  .Spiceway .intro  {height: 38rem;}
  .Spiceway .intro h3 { 
    font-size: 2.5rem;
    line-height: 4.5rem;;
    word-spacing: 5px;
    text-transform: none;
    margin-bottom: 50px;
  }
  /* Footer */
  .Spiceway .section.footer .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: unset;
  }
  .Spiceway .section.footer .footer-address {
    flex-direction: row;
    align-items: unset;
    margin-bottom: 0;
  }
  .Spiceway  .section.footer .brand-logo {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .Spiceway .section.footer .biz-add-line,  
  .Spiceway .section.footer .biz-phone {
    text-align: left;
  }
  .Spiceway .section.footer .footer-social {
    align-items: flex-end;
  }
  /* Product Page */
  .Spiceway .products .search {margin-bottom: 40px;}
  .Spiceway .input-group-append {font-size: 16px;}
  .Spiceway .prd-cat-title {margin-top: 0;}
  /* Contact Us */
  .Spiceway .mob-order {order: 1;}
  .Spiceway .section.contactus .form-btn-wrapper {margin-bottom: 0;}
  .Spiceway .mapheading{color: #3d056b;}
}

/* @media (min-width: 992px) */
@media (min-width: 992px) {
   /* Header */
   /* ---------------------------------------------------------------------- */
   .Spiceway .navbar {padding: 30px 0;}
   .Spiceway .navbar-brand .brand-logo {height: 45px;}
   .Spiceway .navbar-light .navbar-nav .nav-link {padding: 8px 16px 8px 16px; font-size: 16px;}
}

